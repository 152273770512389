import { Fade } from 'react-awesome-reveal'
import { HowItWorksItem } from '#cmsApi/howPage'
import { ProductFeatureItem, StrapiImage } from '#cmsApi/types'
import { SectionHeading } from '#components/Headings'
import {
  CircledNumber,
  Icon,
  Label,
  NumberedStepsSectionContainer,
  Step,
  StepsContainer,
} from './NumberedStepsSection.style'

type NumberedStepProps = {
  stepNumber: number
  label: string
  description: string | null
  image: StrapiImage | null
}

const NumberedStep = ({ stepNumber, label, description, image }: NumberedStepProps) => (
  <Step>
    <Fade>
      <CircledNumber withIcon={!!image}>
        {image ? (
          <Icon src={image.data?.attributes.url} alt={image.data?.attributes.alternativeText} />
        ) : (
          stepNumber
        )}
      </CircledNumber>
    </Fade>
    <Fade>
      <Label>{label}</Label>
    </Fade>
    <Fade>
      <p>{description}</p>
    </Fade>
  </Step>
)

type Props = {
  heading: string
  numberedSteps: HowItWorksItem[] | ProductFeatureItem[]
  image: StrapiImage | null
}

export const NumberedStepsSection = ({ heading, numberedSteps, image }: Props) => {
  const backgroundImage = image?.data?.attributes
  const { large } = backgroundImage?.formats || {}

  return (
    <NumberedStepsSectionContainer imageUrl={backgroundImage?.url} largeImageUrl={large?.url}>
      <SectionHeading>{heading}</SectionHeading>
      <StepsContainer>
        {numberedSteps.map((step, index) => {
          const { label, description, icon } = step as ProductFeatureItem

          return (
            <NumberedStep
              key={label}
              stepNumber={index + 1}
              label={label}
              description={description}
              image={icon}
            />
          )
        })}
      </StepsContainer>
    </NumberedStepsSectionContainer>
  )
}

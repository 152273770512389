import { ComponentProps } from 'react'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import {
  CallToActionButton as StyledCallToActionButton,
  CallToActionLink as StyledCallToActionLink,
  Link as StyledLink,
} from './ButtonsAndLinks.style'
import { ChevronRightIcon } from './ChevronIcon'

type ExtraCTAProps = {
  level?: 'primary' | 'secondary'
  size?: 'normal' | 'large'
  trackingName: string
}

export const CallToActionLink = (
  props: Omit<ComponentProps<typeof StyledCallToActionLink>, '$level' | '$size'> &
    React.AnchorHTMLAttributes<HTMLAnchorElement> &
    ExtraCTAProps,
) => {
  const analytics = useAnalytics()

  const { children, onClick, level = 'primary', size = 'normal', trackingName, ...rest } = props

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    onClick?.(e)
    analytics?.track(AnalyticsEventName.LinkClick, {
      link_name: `CTA link - ${trackingName}`,
    })
  }

  return (
    <StyledCallToActionLink {...rest} $level={level} $size={size} onClick={handleClick}>
      {children}
    </StyledCallToActionLink>
  )
}

export const CallToActionButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & Omit<ExtraCTAProps, 'level'>,
) => {
  const analytics = useAnalytics()

  return (
    <StyledCallToActionButton
      {...props}
      $size={props.size || 'normal'}
      onClick={(e) => {
        props.onClick?.(e)
        analytics?.track(AnalyticsEventName.ButtonClick, {
          button_name: `CTA button - ${props.trackingName}`,
        })
      }}
    />
  )
}

type LinkProps = {
  href: string
  text: string
  trackingName: string
}

export const InternalLink = ({ href, text, trackingName }: LinkProps) => {
  const analytics = useAnalytics()

  return (
    <StyledLink
      href={href}
      onClick={() =>
        analytics?.track(AnalyticsEventName.LinkClick, {
          link_name: `Internal link - ${trackingName}`,
        })
      }
    >
      {text} <ChevronRightIcon />
    </StyledLink>
  )
}

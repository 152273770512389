import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { ContactForm } from '#components/Forms/ContactForm'
import { routes } from '#routes'
import { SubmitMessage } from '../Home/HiringAndNewsletterSection/HiringAndNewsletterSection.style'
import {
  FormHeading,
  Heading,
  LeftSection,
  LogoImg,
  LogoWrapper,
  RightSection,
  Contact as StyledContact,
  SubmitConfirmation,
  TextBlock,
} from './Contact.style'

export const Contact = () => {
  const [responseMessage, setResponseMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const router = useRouter()
  const bookDemoParam = router.query['book_demo'] as string | undefined
  const isBookDemo = bookDemoParam === 'true'

  return (
    <StyledContact>
      <LeftSection>
        <LogoWrapper>
          <NextLink href="/">
            <LogoImg
              className="logo"
              src="/images/netpurpose-logo-black.svg"
              alt="Net Purpose logo"
            />
          </NextLink>
        </LogoWrapper>
        <Heading>Start measuring your impact.</Heading>
        <TextBlock>
          Get access to every fact that a company has ever disclosed on impact. Whether you’re
          looking to discover new investment opportunities or report on your existing portfolio to
          meet regulation, Net Purpose is your go to impact investing services provider.
        </TextBlock>
      </LeftSection>
      <RightSection>
        <FormHeading>{isBookDemo ? 'Book your demo' : 'Contact us'}</FormHeading>
        {responseMessage ? (
          <SubmitConfirmation>
            <SubmitMessage>{responseMessage}</SubmitMessage>
            <CallToActionLink href={routes.home()} trackingName="Contact - Return to the homepage">
              Return to the homepage
            </CallToActionLink>
          </SubmitConfirmation>
        ) : (
          <ContactForm
            setResponseMessage={setResponseMessage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isBookDemo={isBookDemo}
          />
        )}
      </RightSection>
    </StyledContact>
  )
}

import { LinkProps as NextLinkProps } from 'next/link'
import { Button } from './BackButton.style'
import { ChevronLeftIcon } from './ChevronIcon'

export const BackButton = (props: NextLinkProps) => {
  return (
    <Button {...props}>
      <ChevronLeftIcon />
      Back
    </Button>
  )
}

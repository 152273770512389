import NextLink from 'next/link'
import { Fade } from 'react-awesome-reveal'
import styled, { css } from 'styled-components'

const sharedStyles = css<{ $size: 'normal' | 'large' }>`
  background-color: ${({ theme }) => theme.colors.actionPrimary};
  color: ${({ theme }) => theme.colors.typePrimary};
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.9375rem ${({ $size }) => ($size === 'large' ? '4.5rem' : '1.5rem')};
  border: 1px solid ${({ theme }) => theme.colors.architecture8};
  border-radius: 120px;
  white-space: nowrap;
  text-align: center;
  display: inline-block;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgDark};
    color: ${({ theme }) => theme.colors.architecture1};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
    display: auto;
  }
`

export const StyledFade = styled(Fade)`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
  }
`

export const CallToActionLink = styled(NextLink)<{
  $level: 'primary' | 'secondary'
  $size: 'normal' | 'large'
}>`
  ${sharedStyles}
  background-color: ${({ theme, $level }) =>
    $level === 'primary' ? theme.colors.actionPrimary : theme.colors.architecture1};
`

export const CallToActionButton = styled.button<{
  $size: 'normal' | 'large'
}>`
  ${sharedStyles}
`

export const Link = styled(NextLink)`
  text-transform: uppercase;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.typePrimary};
  font-weight: bold;
  display: flex;
  align-items: center;
  /* Between text and chevron icon. */
  gap: 10px;
  letter-spacing: 0.3rem;
  text-align: center;

  &:hover {
    color: ${({ theme }) => theme.colors.actionPrimary};
  }
` as typeof NextLink

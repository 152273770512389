import { WhoPageAttributes } from '#cmsApi/whoPage'
import { LargeCircleBanner, Main, TitleSection } from '#components/sections'
import { IntroSection } from '#components/sections'
import { routes } from '#routes'
import { TeamMembers } from './TeamMembers'

type Props = {
  whoPageContent: WhoPageAttributes
}

export const Who = ({ whoPageContent }: Props) => {
  const { titleSection, intro, bigCircleBanner, team, community } = whoPageContent

  return (
    <Main>
      <TitleSection scrollButtonIsVisible {...titleSection} />
      <IntroSection {...intro} url={routes.contact()} pageName="Who" />
      <TeamMembers heading="Our team" teamMembers={team} />
      <TeamMembers heading="Our community" teamMembers={community} darkBackground />
      {/* TODO: update CMS to make large/big naming consistent */}
      <LargeCircleBanner {...bigCircleBanner} url={routes.hiring()} pageName="Who" />
    </Main>
  )
}

import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { shortHeaderHeight, tallHeaderHeight } from '../Header/Header.style'

export const TitleSectionContainer = styled.section<{ isBannerVisible: boolean }>`
  position: relative;
  height: calc(100vh - ${shortHeaderHeight});
  display: flex;
  flex-direction: column;
  justify-content: ${({ isBannerVisible }) => (isBannerVisible ? 'flex-start' : 'center')};
  align-items: center;
  gap: ${({ isBannerVisible, theme }) => (isBannerVisible ? theme.spacing.medium : 0)};

  padding: 0 ${({ theme }) => `${theme.spacing.regular} ${theme.spacing.large}`};

  @media (min-height: 670px) {
    gap: ${({ isBannerVisible, theme }) =>
      isBannerVisible ? `calc(${theme.spacing.large} * 1.25)` : 0};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 ${({ theme }) => `${theme.spacing.large} ${theme.spacing.large}`};
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    height: calc(100vh - ${tallHeaderHeight});
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    /* Prettier prevents this being able to fit all on one line, causing formatting issues. */
    @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
      gap: ${({ isBannerVisible, theme }) =>
        isBannerVisible ? `calc(${theme.spacing.large} * 1.5)` : 0};
    }
  }
`

export const CentredContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

export const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  font-family: Blacker, serif;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: ${({ theme }) => `calc(${theme.sizes.centeredTextMaxWidth} / 1.5)`};

    @media (min-height: 620px) {
      font-size: 2rem;
      line-height: 3.5rem;
      max-width: ${({ theme }) => theme.sizes.centeredTextMaxWidth};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }
`

export const Subtitle = styled(ReactMarkdown)`
  text-align: center;
  max-width: 30rem;

  font-size: 1rem;
  line-height: 1.5rem;
`

export const ScrollButtonTarget = styled.div`
  position: absolute;
  bottom: ${shortHeaderHeight};

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    bottom: ${tallHeaderHeight};
  }
`

import { HowPageAttributes } from '#cmsApi/howPage'
import { Main, TitleSection } from '#components/sections'
import { LargeCircleBanner } from '#components/sections/LargeCircleBanner'
import { NumberedStepsSection } from '#components/sections/NumberedStepsSection'
import { SemiCircleFeaturesSection } from '#components/sections/SemiCircleFeaturesSection'
import { TintedSection } from '#components/sections/TintedSection'
import { routes } from '#routes'

export const How = ({
  titleSection,
  howItWorksHeading,
  howItWorks,
  howItWorksBackground,
  ourDataHeading,
  ourData,
  toolsHeading,
  tools,
  toolsCTA,
  toolsMainImage,
  bigCircleBanner,
}: HowPageAttributes) => (
  <Main>
    <TitleSection scrollButtonIsVisible {...titleSection} />
    <NumberedStepsSection
      heading={howItWorksHeading}
      image={howItWorksBackground}
      numberedSteps={howItWorks}
    />
    <TintedSection
      mainHeading={ourDataHeading}
      {...ourData}
      url={routes.contact()}
      pageName="How"
    />
    <SemiCircleFeaturesSection
      heading={toolsHeading}
      items={tools}
      callToAction={toolsCTA}
      url={routes.contact()}
      image={toolsMainImage}
      pageName="How"
    />
    <LargeCircleBanner {...bigCircleBanner} url={routes.contact()} pageName="How" />
  </Main>
)

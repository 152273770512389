import styled from 'styled-components'
import { CTASection } from '#cmsApi/types'
import { getBackgroundImageOrColor } from '../shared.style'

export const Container = styled.section<{
  whiteBackground: CTASection['whiteBackground']
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, whiteBackground }) =>
    whiteBackground ? theme.colors.bgSecondary : theme.colors.bgPrimary};
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ theme }) => theme.spacing.medium};
  }
`

export const LargeCircleBanner = styled.div<{
  imageUrl: string | undefined
  mediumImageUrl: string | undefined
  largeImageUrl: string | undefined
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme, mediumImageUrl, imageUrl }) =>
    getBackgroundImageOrColor(theme, mediumImageUrl, imageUrl)}
  background-size: cover;
  background-position: center;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.regular}`};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${({ theme, largeImageUrl, imageUrl }) =>
      getBackgroundImageOrColor(theme, largeImageUrl, imageUrl)}
    width: 42rem;
    height: 42rem;
    border-radius: 50%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    // The default image url is the highest quality one.
    ${({ theme, imageUrl }) => getBackgroundImageOrColor(theme, imageUrl, imageUrl)}
  }
`

export const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.architecture1};
  font-size: 3rem;
  font-family: Blacker, serif;
  max-width: 20rem;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

import styled from 'styled-components'
import { shortHeaderHeight, tallHeaderHeight } from '../Header/Header.style'

export const Main = styled.main`
  position: relative;
  top: ${shortHeaderHeight};

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    top: ${tallHeaderHeight};
  }
`

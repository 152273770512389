import { HiringPageAttributes } from '#cmsApi/hiringPage'
import {
  IntroSection,
  LargeCircleBanner,
  Main,
  TitleSection,
  VerticalCircleBanner,
} from '#components/sections'
import { config } from '#config'
import { routes } from '#routes'

export const Hiring = ({
  titleSection,
  intro,
  introBackgroundImage,
  linkedInSection,
  emailUsSection,
  bigCircleBanner,
}: HiringPageAttributes) => (
  <Main>
    <TitleSection scrollButtonIsVisible {...titleSection} />
    <IntroSection body={intro} image={introBackgroundImage} pageName="Hiring" />
    <VerticalCircleBanner {...linkedInSection} url={config.linkedInUrl} variation="light" />
    <VerticalCircleBanner {...emailUsSection} url={config.emailUsUrl} variation="dark" />
    <LargeCircleBanner {...bigCircleBanner} url={routes.contact()} pageName="Hiring" />
  </Main>
)

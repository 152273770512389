import { css } from 'styled-components'
import { Theme } from '#themes'
import { shortHeaderHeight, tallHeaderHeight } from './Header/Header.style'

export const framedSectionStyles = css`
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 1.5rem;
    min-height: calc(100vh - ${shortHeaderHeight} - (1.5rem * 2));
    border-radius: 15px;

    @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
      min-height: calc(100vh - ${tallHeaderHeight} - (1.5rem * 2));
    }
  }
`

export const getBackgroundImageOrColor = (
  theme: Theme,
  preferredUrl?: string,
  fallbackUrl?: string,
): string =>
  preferredUrl || fallbackUrl
    ? `background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${
        preferredUrl || fallbackUrl
      });`
    : `background-color: ${theme.colors.bgDark};`

import { HomePageAttributes } from '#cmsApi/homePage'
import { SectionHeading } from '#components/Headings'
import {
  CompanyLogo,
  ImageContainer,
  InnerContainer,
  CompanyLogos as StyledCompanyLogos,
} from './CompanySlider.style'

type Props = {
  title: string
  logos: HomePageAttributes['companySlider']['logos']
}

export const CompanySlider = ({ title, logos }: Props) => {
  const images = logos?.data
  if (!images?.length) {
    return null
  }

  return (
    <StyledCompanyLogos>
      <SectionHeading>{title}</SectionHeading>
      <InnerContainer>
        {images.map((image) => (
          <ImageContainer key={image.id}>
            <CompanyLogo src={image.attributes.url} alt={image.attributes.alternativeText} />
          </ImageContainer>
        ))}
      </InnerContainer>
    </StyledCompanyLogos>
  )
}

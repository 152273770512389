import styled from 'styled-components'

export const TintedSectionContainer = styled.section`
  display: block;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 5rem ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    padding: 10rem ${({ theme }) => theme.spacing.large};
    gap: ${({ theme }) => theme.spacing.large};
  }
`

export const DesktopImageContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const MobileTabletImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`

export const TintedSectionImage = styled.img`
  max-height: 15rem;
  max-width: 15rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: calc(15rem * 1.5);
    max-height: calc(15rem * 1.5);
  }
`

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
    align-items: flex-start;
  }
`

export const LargeHeading = styled.h2`
  margin-bottom: 3.5rem;
  font-family: Blacker, serif;
  font-size: 2.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 3.75rem;
  }
`

export const Body = styled.p`
  margin-bottom: 3.5rem;
  font-size: 1.3125rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.5rem;
  }
`

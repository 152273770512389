import { BannerContainer, DismissButton, DismissIcon, MessageMarkdown } from './Banner.style'

export type BannerMessage = { id: string; markdown: string }

type Props = {
  message: BannerMessage
  onDismissBanner: () => void
}

export const Banner = ({ message, onDismissBanner }: Props) => (
  <BannerContainer>
    <MessageMarkdown>{message.markdown}</MessageMarkdown>
    <DismissButton onClick={onDismissBanner}>
      <DismissIcon src="/images/icons/close.svg" alt="Close icon" />
    </DismissButton>
  </BannerContainer>
)

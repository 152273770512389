import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { HiringAndNewsletterSection as HiringAndNewsletterSectionType } from '#cmsApi/homePage'
import { CallToActionButton, CallToActionLink } from '#components/ButtonsAndLinks'
import { SectionHeading } from '#components/Headings'
import { LoadingSpinner } from '#components/LoadingSpinner'
import { routes } from '#routes'
import {
  Container,
  FormValidationMessage,
  HiringSection,
  InnerContainer,
  Input,
  NewsletterForm,
  NewsletterSectionContainer,
  NewsletterTextContainer,
  SubmitMessage,
} from './HiringAndNewsletterSection.style'

type FormData = {
  name: string
  email: string
}

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Please enter a valid email address').required('Email is required'),
  })
  .required()

type Props = HiringAndNewsletterSectionType

export const NewsletterSection = ({
  newsletterTitle,
  newsletterBody,
  newsletterButtonText,
  isColumn = true,
}: {
  newsletterTitle: string
  newsletterBody: string
  newsletterButtonText: string
  isColumn?: boolean
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const [responseMessage, setResponseMessage] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    const res = await fetch('/api/newsletter', {
      method: 'POST',
      body: JSON.stringify({
        name: data.name,
        email: data.email,
      }),
    })
    setIsLoading(false)
    if (res.status === 201) {
      setResponseMessage("Thank you, we'll be in touch")
    } else {
      setResponseMessage('Sorry, we had an error, please refresh and try again')
    }
  })

  return (
    <NewsletterSectionContainer $isColumn={isColumn}>
      <NewsletterTextContainer>
        <SectionHeading style={{ marginBottom: 0 }}>{newsletterTitle}</SectionHeading>
        <Fade>
          <p>{newsletterBody}</p>
        </Fade>
      </NewsletterTextContainer>
      {responseMessage ? (
        <SubmitMessage>{responseMessage}</SubmitMessage>
      ) : (
        <NewsletterForm onSubmit={onSubmit}>
          <Input placeholder="Enter full name" {...register('name', { required: true })} />
          <FormValidationMessage>{errors.name?.message}</FormValidationMessage>
          <Input placeholder="Enter email address" {...register('email', { required: true })} />
          <FormValidationMessage>{errors.email?.message}</FormValidationMessage>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <CallToActionButton
              type="submit"
              trackingName={`Home - Newsletter section - ${newsletterButtonText}`}
            >
              {newsletterButtonText}
            </CallToActionButton>
          )}
        </NewsletterForm>
      )}
    </NewsletterSectionContainer>
  )
}

export const HiringAndNewsletterSection = ({
  hiringTitle,
  hiringBody,
  hiringButtonText,
  newsletterTitle,
  newsletterBody,
  newsletterButtonText,
}: Props) => {
  return (
    <Container>
      <InnerContainer>
        <HiringSection>
          <SectionHeading style={{ marginBottom: 0 }}>{hiringTitle}</SectionHeading>
          <Fade>
            <p>{hiringBody}</p>
          </Fade>
          <CallToActionLink
            href={routes.hiring()}
            trackingName={`Home - Hiring section - ${hiringButtonText}`}
          >
            {hiringButtonText}
          </CallToActionLink>
        </HiringSection>
        <NewsletterSection
          newsletterTitle={newsletterTitle}
          newsletterBody={newsletterBody}
          newsletterButtonText={newsletterButtonText}
        />
      </InnerContainer>
    </Container>
  )
}

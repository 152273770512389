import { Fade } from 'react-awesome-reveal'
import { CTASection } from '#cmsApi/types'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import {
  Container,
  Heading,
  LargeCircleBanner as StyledLargeCircleBanner,
} from './LargeCircleBanner.style'

type Props = CTASection & { pageName: string }

export const LargeCircleBanner = ({
  image,
  heading,
  callToAction,
  url,
  whiteBackground,
  pageName,
}: Props) => {
  const backgroundImage = image.data?.attributes
  const { medium, large } = backgroundImage?.formats || {}

  return (
    <Container whiteBackground={whiteBackground}>
      <StyledLargeCircleBanner
        imageUrl={backgroundImage?.url}
        mediumImageUrl={medium?.url}
        largeImageUrl={large?.url}
      >
        {heading && (
          <Fade>
            <Heading>{heading}</Heading>
          </Fade>
        )}
        <CallToActionLink
          href={url}
          trackingName={`${pageName} - Large circle banner - ${callToAction}`}
        >
          {callToAction}
        </CallToActionLink>
      </StyledLargeCircleBanner>
    </Container>
  )
}

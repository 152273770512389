import styled from 'styled-components'

export const Container = styled.section`
  padding: 5rem ${({ theme }) => theme.spacing.regular};
  background-color: ${({ theme }) => theme.colors.architecture4};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 5rem ${({ theme }) => theme.spacing.large};
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    flex-direction: row;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin-bottom: 0;
    width: 50%;
  }
`

export const Image = styled.img`
  border-radius: 50%;
  padding: 2rem;
  min-width: 15rem;
  min-height: 15rem;
  width: 30vw;
  height: 30vw;
  overflow: hidden;
  object-fit: contain;
  background-color: ${({ theme }) => theme.colors.bgSecondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 30rem;
    max-height: 30rem;
  }
`

export const TextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    align-items: flex-start;
    margin-left: ${({ theme }) => theme.spacing.medium};
    width: 50%;
  }
`

export const BodyText = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`

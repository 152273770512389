import styled from 'styled-components'
import { LargeHeading } from '#components/Headings'
import { framedSectionStyles, getBackgroundImageOrColor } from '#components/sections/shared.style'

export const HowWeHelpSectionContainer = styled.section<{
  imageUrl: string | undefined
  largeImageUrl: string | undefined
}>`
  ${framedSectionStyles}
  /* Medium image is too grainy to use by default here, as the container is
    quite tall on a narrow screen. */
  ${({ theme, largeImageUrl, imageUrl }) =>
    getBackgroundImageOrColor(theme, largeImageUrl, imageUrl)}
  background-size: cover;
  background-position: center;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.medium}`};
  text-align: center;
  color: ${({ theme }) => theme.colors.architecture1};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    // The default image url is the highest quality one.
    ${({ theme, imageUrl }) => getBackgroundImageOrColor(theme, imageUrl, imageUrl)}
    padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.large};
  }
`

export const HowWeHelpHeading = styled(LargeHeading)`
  padding: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 0 ${({ theme }) => theme.spacing.medium};
  }
`

export const HowWeHelpItemContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    gap: 2.75rem;
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    min-width: ${({ theme }) => theme.spacing.large};
  }
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 1rem;

  color: ${({ theme }) => theme.colors.typePrimary};
  background-color: ${({ theme }) => theme.colors.architecture4};
  font-size: 1.125rem;

  margin-bottom: ${({ theme }) => theme.spacing.medium};
  border-radius: 10px;
  padding-top: ${({ theme }) => theme.spacing.regular};
  min-width: 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin-bottom: 4.5rem;
    min-width: 8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    font-size: 1.5rem;
    min-width: 10rem;
  }
`

export const HowWeHelpItemImage = styled.img`
  width: 3.5rem;
  height: 3.5rem;
`

export const LabelContainer = styled.p`
  margin: 0 1.5rem 1.5rem 1.5rem;
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    margin: 0 2rem 2rem 2rem;
  }
`

import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { framedSectionStyles, getBackgroundImageOrColor } from '#components/sections/shared.style'

export const IntroSection = styled.section<{
  imageUrl: string | undefined
  mediumImageUrl: string | undefined
  largeImageUrl: string | undefined
}>`
  ${framedSectionStyles}
  ${({ theme, mediumImageUrl, imageUrl }) =>
    getBackgroundImageOrColor(theme, mediumImageUrl, imageUrl)}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 7rem ${({ theme }) => theme.spacing.regular};

  > * + * {
    margin-top: 2.25rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-top: 5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${({ theme, largeImageUrl, imageUrl }) =>
      getBackgroundImageOrColor(theme, largeImageUrl, imageUrl)}
    padding: 15rem ${({ theme }) => theme.spacing.regular};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    // The default image url is the highest quality one.
    ${({ theme, imageUrl }) => getBackgroundImageOrColor(theme, imageUrl, imageUrl)}
  }
`

export const Body = styled(ReactMarkdown)`
  color: ${({ theme }) => theme.colors.architecture1};
  font-size: 1.3125rem;
  max-width: ${({ theme }) => theme.sizes.centeredTextMaxWidth};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.5rem;
  }
`

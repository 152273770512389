import Image from 'next/image'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { config } from '#config'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import { routes } from '#routes'
import {
  AddressHeading,
  AddressOrLegalInfoContainer,
  ContactAndFollow,
  ContactUsLinks,
  Email,
  FooterContainer,
  GroupColumn,
  LeftSection,
  LegalInfo,
  LogoImg,
  PrivacyNoticeLink,
  SocialLinkWrapper,
  SubHeading,
} from './Footer.style'

type Props = {
  withHeader: boolean
}

export const Footer = ({ withHeader }: Props) => {
  const analytics = useAnalytics()

  return (
    <FooterContainer $withHeader={withHeader}>
      <LeftSection>
        <GroupColumn>
          <LogoImg
            src="/images/netpurpose-logo-black.svg"
            alt="Net Purpose logo"
            width={209}
            height={32}
          />
          <PrivacyNoticeLink
            href={config.privacyPolicyUrl}
            target="_blank"
            rel="noopener"
            onClick={() => {
              analytics?.track(AnalyticsEventName.LinkClick, {
                link_name: 'Privacy notice',
              })
            }}
          >
            Privacy notice
          </PrivacyNoticeLink>
          <AddressOrLegalInfoContainer>
            <LegalInfo>
              Net Purpose Ltd. Incorporated and registered in England and Wales, company number
              11725899.
            </LegalInfo>
          </AddressOrLegalInfoContainer>
        </GroupColumn>

        <ContactAndFollow>
          <GroupColumn>
            <SubHeading>Contact</SubHeading>
            <Email>contact@netpurpose.com</Email>
            <AddressOrLegalInfoContainer>
              <AddressHeading>Registered Address</AddressHeading>
              <p>9th Floor 107 Cheapside,</p>
              <p>London,</p>
              <p>EC2V 6DN,</p>
              <p>United Kingdom</p>
            </AddressOrLegalInfoContainer>
          </GroupColumn>

          <GroupColumn>
            <SubHeading>Follow</SubHeading>
            <SocialLinkWrapper
              href="https://www.linkedin.com/company/net-purpose"
              onClick={() =>
                analytics?.track(AnalyticsEventName.LinkClick, {
                  link_name: 'Social link - LinkedIn',
                })
              }
              target="__blank"
              rel="noopener noreferrer"
            >
              <Image
                src="/images/icons/linkedin-black.svg"
                alt="LinkedIn logo"
                width={24}
                height={24}
              />
            </SocialLinkWrapper>
            <AddressOrLegalInfoContainer>
              <AddressHeading>Office</AddressHeading>
              <p>The Black & White Building,</p>
              <p>74 Rivington St,</p>
              <p>London,</p>
              <p>EC2A 3AY,</p>
              <p>United Kingdom</p>
            </AddressOrLegalInfoContainer>
          </GroupColumn>
        </ContactAndFollow>
      </LeftSection>
      <ContactUsLinks>
        <CallToActionLink
          href={`${routes.contact()}?book_demo=true`}
          size="large"
          trackingName="Footer - Book demo"
        >
          Book Demo
        </CallToActionLink>
        <CallToActionLink
          href={routes.contact()}
          level="secondary"
          size="large"
          trackingName="Footer - Talk to us"
        >
          Talk to us
        </CallToActionLink>
      </ContactUsLinks>
    </FooterContainer>
  )
}

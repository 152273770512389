import styled from 'styled-components'

export const Contact = styled.main`
  display: flex;
  flex-direction: column;
  padding: 3.75rem 2.5rem;
  gap: ${({ theme }) => theme.spacing.regular};
  margin: 0 auto;
  max-width: ${({ theme }) => theme.maxWidth};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.large};
    /* Prevent footer jumping up after form submission. */
    min-height: calc(100vh - 10rem);
  }
`

export const LeftSection = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 35rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 38rem;
  }
`

export const RightSection = styled.section`
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 2rem;
  max-width: 45.75rem;
  flex: 1;
`

export const LogoWrapper = styled.div`
  margin-bottom: 2.5rem;
`

export const LogoImg = styled.img`
  height: 2rem;
  cursor: pointer;
`

export const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.typePrimary};
  font-family: Blacker, serif;
  font-size: 3rem;
  line-height: 4rem;
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

export const TextBlock = styled.p`
  color: ${({ theme }) => theme.colors.typePrimary};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

export const FormHeading = styled.h2`
  color: ${({ theme }) => theme.colors.typePrimary};
  font-family: Blacker, serif;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
`

export const SubmitConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const SubmitMessage = styled.div`
  margin-bottom: 2.5rem;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
`

import { Fade } from 'react-awesome-reveal'
import { StrapiImage } from '#cmsApi/types'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { Body, IntroSection as StyledIntroSection } from './IntroSection.style'

type Props = {
  heading?: string | null // Currently unused
  body: string | null // Markdown
  callToAction?: string
  url?: string
  image: StrapiImage
  pageName: string
}

export const IntroSection = ({ image, body, callToAction, url, pageName }: Props) => {
  const backgroundImage = image.data?.attributes
  const { medium, large } = backgroundImage?.formats || {}

  return (
    <StyledIntroSection
      imageUrl={backgroundImage?.url}
      mediumImageUrl={medium?.url}
      largeImageUrl={large?.url}
    >
      <Fade direction="up">
        {body && <Body>{body}</Body>}
        {callToAction && url && (
          <CallToActionLink
            href={url}
            trackingName={`${pageName} - Intro section - ${callToAction}`}
          >
            {callToAction}
          </CallToActionLink>
        )}
      </Fade>
    </StyledIntroSection>
  )
}

import styled, { css } from 'styled-components'

export const sectionHeadingStyle = css`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.3rem;
  font-size: 1.5rem;
`

export const SectionHeading = styled.h2`
  ${sectionHeadingStyle};

  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1rem;
  }
`

export const LargeHeading = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  font-family: Blacker, serif;
  font-size: 2.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 3.75rem;
  }
`

import styled from 'styled-components'

export const ItemContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.regular};
  margin-bottom: 3.5rem;

  &:last-of-type {
    margin-bottom: 5.3125rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin-bottom: 3.5rem;
  }
`

export const ItemImage = styled.img`
  width: 3.4375rem;
  height: 3.4375rem;
  object-fit: contain;
`

export const ContentContainer = styled.div`
  flex: 1;
`

export const Label = styled.h3`
  font-size: 1.3125rem;
  text-transform: uppercase;
  letter-spacing: 0.2625rem;
  margin-bottom: 1.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.5rem;
  }
`

export const Description = styled.p`
  font-size: 1.3125rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.5rem;
  }
`

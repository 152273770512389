import styled from 'styled-components'
import { sectionHeadingStyle } from '#components/Headings/Headings.style'

export const Container = styled.section`
  padding: 5rem ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 10rem ${({ theme }) => theme.spacing.large};
  }
`

export const InnerContainer = styled.div`
  display: block;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: grid;
    grid-template-areas:
      'a b'
      'c d';
    row-gap: ${({ theme }) => theme.spacing.medium};
    column-gap: ${({ theme }) => theme.spacing.large};
    max-width: ${({ theme }) => theme.maxWidth};
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const IconContainer = styled.div`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.architecture4};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 15rem;
  height: 15rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: 10vw;
    height: 10vw;
    max-width: 20rem;
    max-height: 20rem;
  }
`

export const Icon = styled.img`
  width: 50%;
  height: 50%;
`

export const ItemHeading = styled.h3`
  ${sectionHeadingStyle};
  margin-bottom: ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`

export const ItemDescription = styled.p`
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`

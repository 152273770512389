import { isProductionEnv } from '@netpurpose/utils'

export const config = {
  revalidationDelayInSeconds: isProductionEnv() ? 30 : 5,
  linkedInUrl: 'https://www.linkedin.com/company/net-purpose',
  emailUsUrl: 'mailto:contact@netpurpose.com',
  pipeDriveApiBaseUrl: 'https://api.pipedrive.com/v1',
  privacyPolicyUrl: 'https://app.privasee.io/privacy-portal/646cc22bda2352001473b13e',
  featureToggles: {},
} as const

import Image from 'next/image'
import { scroller } from 'react-scroll'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import { StyledScrollButton } from './ScrollButton.style'

const scrollTo = (target: string) => {
  scroller.scrollTo(target, {
    duration: 500,
    smooth: true,
  })
}

export const ScrollButton = ({ target }: { target: string }) => {
  const analytics = useAnalytics()

  return (
    <StyledScrollButton
      name="scroll-button"
      onClick={() => {
        scrollTo(target)
        analytics?.track(AnalyticsEventName.ButtonClick, { button_name: 'Scroll button' })
      }}
    >
      <Image src="/images/icons/chevron-down-icon.svg" alt="Down arrow" width={32} height={32} />
    </StyledScrollButton>
  )
}

import { ComponentProps } from 'react'
import { Fade } from 'react-awesome-reveal'
import {
  LargeHeading as StyledLargeHeading,
  SectionHeading as StyledSectionHeading,
} from './Headings.style'

export const SectionHeading = (props: ComponentProps<typeof StyledSectionHeading>) => (
  <StyledSectionHeading {...props} />
)

export const LargeHeading = (props: ComponentProps<typeof StyledLargeHeading>) => (
  <Fade>
    <StyledLargeHeading {...props} />
  </Fade>
)

import { Fade } from 'react-awesome-reveal'
import { CTASection } from '#cmsApi/types'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { SectionHeading } from '#components/Headings'
import {
  Body,
  ContentContainer,
  DesktopImageContainer,
  LargeHeading,
  MobileTabletImageContainer,
  TintedSectionContainer,
  TintedSectionImage,
} from './TintedSection.style'

type Props = CTASection & {
  mainHeading: string
  pageName: string
}

export const TintedSection = ({
  image,
  heading,
  callToAction,
  body,
  mainHeading,
  url,
  pageName,
}: Props) => {
  const tintedImage = image.data?.attributes

  return (
    <TintedSectionContainer>
      <DesktopImageContainer>
        <TintedSectionImage src={tintedImage?.url} alt={tintedImage?.alternativeText} />
      </DesktopImageContainer>
      <ContentContainer>
        <SectionHeading>{mainHeading}</SectionHeading>
        <Fade>
          <LargeHeading>{heading}</LargeHeading>
        </Fade>
        <Fade>
          <MobileTabletImageContainer>
            <TintedSectionImage src={tintedImage?.url} alt={tintedImage?.alternativeText} />
          </MobileTabletImageContainer>
        </Fade>
        <Fade>
          <Body>{body}</Body>
        </Fade>
        <Fade>
          <CallToActionLink
            href={url}
            trackingName={`${pageName} - ${mainHeading} - ${callToAction}`}
          >
            {callToAction}
          </CallToActionLink>
        </Fade>
      </ContentContainer>
    </TintedSectionContainer>
  )
}

import styled from 'styled-components'
import { getBackgroundImageOrColor } from '#components/sections/shared.style'

export const Posts = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing.regular};
  gap: ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.large};
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    gap: 2rem;
  }
`

export const PostArticle = styled.a<{
  imageUrl: string | undefined
  mediumImageUrl: string | undefined
  largeImageUrl: string | undefined
}>`
  ${({ theme, mediumImageUrl, imageUrl }) =>
    getBackgroundImageOrColor(theme, mediumImageUrl, imageUrl)}
  background-size: 100%;
  transition: all 1s ease;
  background-position: center;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing.regular};
  width: 100%;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-size: 125%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    /* If we have a lot of blog articles we don't want to download loads of full size
       images whatever the speed of the user's internet connection, so large should
       be good enough for even the largest screens. */
    ${({ theme, largeImageUrl, imageUrl }) =>
      getBackgroundImageOrColor(theme, largeImageUrl, imageUrl)}
    min-width: 24rem;
    flex: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: calc(50% - (${({ theme }) => theme.spacing.regular}) / 2);
  }
`

export const PostSectionHeading = styled.h2`
  padding-top: ${({ theme }) => theme.spacing.regular};
  padding-left: ${({ theme }) => theme.spacing.regular};
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-size: 1.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: ${({ theme }) => `calc(${theme.sizes.centeredTextMaxWidth} / 1.5)`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding-left: ${({ theme }) => theme.spacing.large};
    margin-bottom: -${({ theme }) => theme.spacing.regular};
  }
`

export const PostDate = styled.p`
  color: ${({ theme }) => theme.colors.typeDarkPrimary};
  margin-bottom: ${({ theme }) => theme.spacing.regular};
  font-size: 1.125rem;
`

export const PostTitle = styled.h2`
  color: ${({ theme }) => theme.colors.typeDarkPrimary};
  text-transform: uppercase;
  font-size: 1.5rem;
`

export const PostHost = styled.p`
  color: ${({ theme }) => theme.colors.typeDarkPrimary};
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing.regular};
`

export const PostCallToActionText = styled.p`
  color: ${({ theme }) => theme.colors.actionDarkLink};
  text-decoration: underline;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1rem;
`

export const ResourceTitle = styled.h1`
  padding-left: ${({ theme }) => theme.spacing.regular};
  padding-top: ${({ theme }) => theme.spacing.regular};
  font-size: 2.8rem;
  font-family: Blacker, serif;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: ${({ theme }) => `calc(${theme.sizes.centeredTextMaxWidth} / 1.5)`};

    @media (min-height: 620px) {
      font-size: 4.2rem;
      max-width: ${({ theme }) => theme.sizes.centeredTextMaxWidth};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: 5rem;
    }
    padding-left: ${({ theme }) => theme.spacing.large};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    @media (min-height: 850px) {
      font-size: 6rem;
    }
  }
`

import styled from 'styled-components'

export const TeamMembers = styled.section<{ darkBackground: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.regular}`};
  background-color: ${({ darkBackground, theme }) =>
    darkBackground ? theme.colors.bgDark : theme.colors.bgSecondary};
  color: ${({ darkBackground, theme }) =>
    darkBackground ? theme.colors.bgSecondary : theme.colors.bgDark};
`

export const Heading = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
`

export const TeamMemberArticleContainer = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  gap: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.medium}`};
  justify-content: space-evenly;
  max-width: 75%;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: min(90%, ${({ theme }) => theme.maxWidth});
  }
`

export const TeamMemberArticle = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.medium};
  width: 20rem;
`

export const TeamMemberImage = styled.img`
  object-fit: cover;
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
`

export const TeamMemberTextContainer = styled.div`
  text-align: center;
`

export const TeamMemberName = styled.h3`
  font-size: 1.5rem;
`

export const TeamMemberJobTitle = styled.p`
  font-size: 1.5rem;
`

export const LinkedInLogo = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: ${({ theme }) => theme.colors.actionPrimary};
  color: ${({ theme }) => theme.colors.typePrimary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2rem;
`

import { Fade } from 'react-awesome-reveal'
import { valueIsDefined } from '@netpurpose/utils'
import { TeamMember, TeamMemberReference } from '#cmsApi/types'
import {
  Heading,
  LinkedInLogo,
  TeamMemberArticle as StyledTeamMemberArticle,
  TeamMembers as StyledTeamMembers,
  TeamMemberArticleContainer,
  TeamMemberImage,
  TeamMemberJobTitle,
  TeamMemberName,
  TeamMemberTextContainer,
} from './TeamMembers.style'

const TeamMemberArticle = ({ name, jobTitle, profileImage, linkedinUrl }: TeamMember) => (
  <StyledTeamMemberArticle>
    {profileImage?.data && (
      <TeamMemberImage
        // TODO: Ideally we should use srcSets but they don't seem to work as expected.
        // Using the large image if available is big enough for all screen sizes, but
        // uses much less data than the default image size if someone has provided
        // a particularly high-definition image for their profile.
        src={profileImage.data.attributes.formats?.large?.url || profileImage.data?.attributes.url}
        alt={profileImage.data?.attributes.alternativeText}
      />
    )}
    <Fade>
      <TeamMemberTextContainer>
        <TeamMemberName>{name}</TeamMemberName>
        <TeamMemberJobTitle>{jobTitle}</TeamMemberJobTitle>
      </TeamMemberTextContainer>

      {linkedinUrl && (
        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
          <LinkedInLogo>in</LinkedInLogo>
        </a>
      )}
    </Fade>
  </StyledTeamMemberArticle>
)

type Props = {
  heading: string
  teamMembers: TeamMemberReference[]
  darkBackground?: boolean
}

export const TeamMembers = ({ heading, teamMembers, darkBackground = false }: Props) => {
  if (!teamMembers.length) {
    return null
  }

  const members = teamMembers.map((relation) => relation.teamMember.data).filter(valueIsDefined)

  return (
    <StyledTeamMembers darkBackground={darkBackground}>
      <Fade>
        <Heading>{heading}</Heading>
      </Fade>
      <TeamMemberArticleContainer>
        {members?.map((member) => <TeamMemberArticle key={member.id} {...member.attributes} />)}
      </TeamMemberArticleContainer>
    </StyledTeamMembers>
  )
}

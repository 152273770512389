import styled from 'styled-components'
import { shortHeaderHeight, tallHeaderHeight } from './sections/Header/Header.style'

export const Container = styled.div<{ $withHeader: boolean }>`
  display: flex;
  min-height: ${({ $withHeader }) => `calc(100vh - ${$withHeader ? shortHeaderHeight : 0})`};
  flex-direction: column;
  justify-content: space-between;

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: ${({ $withHeader }) => `calc(100vh - ${$withHeader ? tallHeaderHeight : 0})`};
  }
`

export const ChevronRightPath = () => (
  <path
    d="M1.6066 2.3934L13.2132 13L1.6066 23.6066"
    stroke="currentColor"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export const ChevronLeftPath = () => (
  <path
    d="M13 16L9 12L13 8"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
)

export const ChevronRightIcon = () => {
  return (
    <svg width="15" height="20" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ChevronRightPath />
    </svg>
  )
}

export const ChevronLeftIcon = () => {
  return (
    <svg
      role="img"
      fill="none"
      aria-label="alt"
      width="20"
      height="20"
      viewBox="8 5 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ChevronLeftPath />
    </svg>
  )
}

import { useEffect, useState } from 'react'
import { CarouselSection as CarouselSectionType } from '#cmsApi/types'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import {
  Container,
  Description,
  FADE_ANIMATION_IN_MS,
  Image,
  ImageContainer,
  InnerContainer,
  StyledLargeHeading as LargeHeading,
  Name,
  Names,
  StyledSectionHeading as SectionHeading,
  TextContainer,
} from './CarouselSection.style'

type Props = CarouselSectionType

export const CarouselSection = ({ title, heading, carouselItems }: Props) => {
  const [selectedItemId, setSelectedItemId] = useState(carouselItems[0]?.id)
  const selectedItem = carouselItems.find((item) => item.id === selectedItemId)
  const [activeIntervalId, setActiveIntervalId] = useState<number>()

  useEffect(() => {
    // Use setInterval from window to make distinct from NodeJS setInterval.
    const intervalId = window.setInterval(() => {
      setSelectedItemId((val) => {
        const currentIndex = carouselItems.findIndex((item) => item.id === val)
        if (currentIndex === carouselItems.length - 1) {
          return carouselItems[0]?.id
        }
        return carouselItems[currentIndex + 1]?.id
      })
    }, FADE_ANIMATION_IN_MS)
    setActiveIntervalId(intervalId)

    return () => window.clearInterval(intervalId)
  }, [carouselItems])

  const analytics = useAnalytics()

  return (
    <Container id="carousel">
      <InnerContainer>
        <SectionHeading>{title}</SectionHeading>
      </InnerContainer>
      <InnerContainer>
        <TextContainer>
          <LargeHeading>{heading}</LargeHeading>
          <Names>
            {carouselItems.map((item) => (
              <Name
                key={item.id}
                selected={item.id === selectedItemId}
                onClick={() => {
                  window.clearInterval(activeIntervalId)
                  setSelectedItemId(item.id)
                  analytics?.track(AnalyticsEventName.ButtonClick, {
                    button_name: `Carousel item - ${item.name}`,
                  })
                }}
              >
                {item.name}
              </Name>
            ))}
          </Names>
          <Description>{selectedItem?.description}</Description>
        </TextContainer>
        <ImageContainer>
          <Image
            // Using the key ensures the animation is triggered when the image
            // source changes.
            key={selectedItem?.id}
            src={selectedItem?.image.data?.attributes.url}
            alt={selectedItem?.image.data?.attributes.alternativeText}
          />
        </ImageContainer>
      </InnerContainer>
    </Container>
  )
}

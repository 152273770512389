import { Fade } from 'react-awesome-reveal'
import { PressSection as PressSectionType } from '#cmsApi/homePage'
import { InternalLink } from '#components/ButtonsAndLinks'
import { SectionHeading } from '#components/Headings'
import {
  Container,
  LinkContainer,
  Testimonial,
  TestimonialImage,
  TestimonialQuote,
  Testimonials,
} from './PressSection.style'

type Props = PressSectionType & { linkUrl: string }

export const PressSection = ({ title, testimonials, linkText, linkUrl }: Props) => (
  <Container>
    <SectionHeading>{title}</SectionHeading>
    <Testimonials>
      {testimonials.map((item) => {
        const image = item.image.data?.attributes

        return (
          <Testimonial key={item.id}>
            {item.link ? (
              <a
                href={item.link}
                target="_blank"
                rel="noopener"
                aria-label="Navigate to testimonial"
              >
                <TestimonialImage src={image?.url} alt={image?.alternativeText} applyHoverStyles />
              </a>
            ) : (
              <TestimonialImage src={image?.url} alt={image?.alternativeText} />
            )}
            <Fade>
              <TestimonialQuote>"{item.quote}"</TestimonialQuote>
            </Fade>
          </Testimonial>
        )
      })}
    </Testimonials>
    <LinkContainer>
      <InternalLink
        href={linkUrl}
        text={linkText}
        trackingName={`Home - Press section - ${linkText}`}
      />
    </LinkContainer>
  </Container>
)

import { Fade } from 'react-awesome-reveal'
import { TextAndImageSection as TextAndImageSectionType } from '#cmsApi/types'
import { InternalLink } from '#components/ButtonsAndLinks'
import { LargeHeading, SectionHeading } from '#components/Headings'
import {
  Body,
  ContentContainer,
  DesktopImageContainer,
  DesktopLinkContainer,
  Image,
  InnerContainer,
  MobileTabletImageContainer,
  MobileTabletLinkContainer,
  TextAndImageSectionContainer,
} from './TextAndImageSection.style'

type Props = TextAndImageSectionType & {
  linkUrl?: string
  pageName: string
}

export const TextAndImageSection = ({
  title,
  heading,
  image,
  body,
  linkText,
  linkUrl,
  linkUnderImage,
  layout,
  whiteBackground,
  circleImage,
  pageName,
}: Props) => {
  const tintedImage = image.data?.attributes
  const showLink = !!linkUrl && !!linkText

  const internalLinkTrackingName = `${pageName} - ${title} - ${linkText}`

  return (
    <TextAndImageSectionContainer whiteBackground={whiteBackground}>
      <InnerContainer layout={layout}>
        <ContentContainer desktopCentered>
          <DesktopImageContainer circleImage={circleImage}>
            <Image
              src={tintedImage?.url}
              alt={tintedImage?.alternativeText}
              circleImage={circleImage}
            />
          </DesktopImageContainer>
          {showLink && linkUnderImage && (
            <DesktopLinkContainer centerLink>
              <Fade>
                <InternalLink
                  href={linkUrl}
                  text={linkText}
                  trackingName={internalLinkTrackingName}
                />
              </Fade>
            </DesktopLinkContainer>
          )}
        </ContentContainer>
        <ContentContainer>
          <SectionHeading>{title}</SectionHeading>
          <Fade>
            <MobileTabletImageContainer circleImage={circleImage}>
              <Image
                src={tintedImage?.url}
                alt={tintedImage?.alternativeText}
                circleImage={circleImage}
              />
            </MobileTabletImageContainer>
          </Fade>
          <LargeHeading>{heading}</LargeHeading>
          <Fade>
            <Body>{body}</Body>
          </Fade>
          {showLink && !linkUnderImage && (
            <DesktopLinkContainer>
              <Fade>
                <InternalLink
                  href={linkUrl}
                  text={linkText}
                  trackingName={internalLinkTrackingName}
                />
              </Fade>
            </DesktopLinkContainer>
          )}
        </ContentContainer>
        {showLink && (
          <MobileTabletLinkContainer>
            <InternalLink href={linkUrl} text={linkText} trackingName={internalLinkTrackingName} />
          </MobileTabletLinkContainer>
        )}
      </InnerContainer>
    </TextAndImageSectionContainer>
  )
}

import { Fade } from 'react-awesome-reveal'
import { ProductFeatureItem, ProductFeatureSection } from '#cmsApi/types'
import { LargeHeading, SectionHeading } from '#components/Headings'
import {
  InnerContainer,
  ProductFeatureArticle,
  ProductFeatureArticleImage,
  ProductFeatureArticleLabel,
  ProductFeatures as StyledProductFeatures,
  ProductFeaturesSubSection as StyledProductFeaturesSubSection,
  SubSectionInnerContainer,
} from './HowItWorksSection.style'

const ProductFeaturesSubSection = ({
  productFeatureItems,
}: {
  productFeatureItems: ProductFeatureItem[] | undefined
}) => (
  <StyledProductFeaturesSubSection>
    <SubSectionInnerContainer>
      {productFeatureItems &&
        productFeatureItems.map((item) => (
          <ProductFeatureArticle key={item.id} totalItems={productFeatureItems.length}>
            {item.icon.data && (
              <ProductFeatureArticleImage
                src={item.icon.data.attributes.url}
                alt={item.icon.data.attributes.alternativeText}
              />
            )}
            <ProductFeatureArticleLabel>{item.label}</ProductFeatureArticleLabel>
          </ProductFeatureArticle>
        ))}
    </SubSectionInnerContainer>
  </StyledProductFeaturesSubSection>
)

type Props = ProductFeatureSection

export const HowItWorksSection = ({ sectionTitle, productFeatureItem, sectionHeading }: Props) => {
  return (
    <StyledProductFeatures>
      <Fade>
        <SectionHeading>{sectionTitle}</SectionHeading>
        <LargeHeading>{sectionHeading}</LargeHeading>
        <InnerContainer>
          <ProductFeaturesSubSection productFeatureItems={productFeatureItem} />
        </InnerContainer>
      </Fade>
    </StyledProductFeatures>
  )
}

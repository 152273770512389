import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CallToActionButton } from '#components/ButtonsAndLinks'
import { LoadingSpinner } from '#components/LoadingSpinner'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import {
  ButtonContainer,
  FormValidationMessage,
  Input,
  InputContainer,
  Label,
  NewsletterCheckbox,
  NewsletterCheckboxContainer,
  ContactForm as StyledContactForm,
  TextArea,
} from './ContactForm.style'

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    companyName: yup.string().required('Company name is required'),
    email: yup.string().email('Please enter a valid email address').required('Email is required'),
    message: yup.string(),
    newsletter: yup.boolean(),
  })
  .required()

type Props = {
  setResponseMessage: (message: string) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  isBookDemo: boolean
}

export const ContactForm = ({ setResponseMessage, isLoading, setIsLoading, isBookDemo }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const analytics = useAnalytics()

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    const res = await fetch('/api/contact', {
      method: 'POST',
      body: JSON.stringify({
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        email: data.email,
        message: data.message,
        newsletter: data.newsletter,
      }),
    })
    setIsLoading(false)
    if (res.status === 201) {
      setResponseMessage("Thank you, we'll be in touch.")
      analytics?.track(AnalyticsEventName.FormSubmit, {
        form_name: 'Contact form',
        form_submit_success: true,
        newsletter_subscribed: data.newsletter,
        book_demo: isBookDemo,
      })
    } else {
      setResponseMessage('Sorry, we had an error, please refresh and try again.')
      analytics?.track(AnalyticsEventName.FormSubmit, {
        form_name: 'Contact form',
        form_submit_success: false,
        newsletter_subscribed: data.newsletter,
        book_demo: isBookDemo,
      })
    }
  })

  return (
    <StyledContactForm onSubmit={onSubmit}>
      <InputContainer>
        <Label>First name</Label>
        <Input {...register('firstName', { required: true })} />
        <FormValidationMessage>{errors.firstName?.message}</FormValidationMessage>
      </InputContainer>
      <InputContainer>
        <Label>Last name</Label>
        <Input {...register('lastName', { required: true })} />
        <FormValidationMessage>{errors.lastName?.message}</FormValidationMessage>
      </InputContainer>
      <InputContainer>
        <Label>Company name</Label>
        <Input {...register('companyName', { required: true })} />
        <FormValidationMessage>{errors.companyName?.message}</FormValidationMessage>
      </InputContainer>
      <InputContainer>
        <Label>Work email</Label>
        <Input {...register('email', { required: true })} />
        <FormValidationMessage>{errors.email?.message}</FormValidationMessage>
      </InputContainer>
      <InputContainer>
        <Label>Message (optional)</Label>
        <TextArea {...register('message', { required: false })} rows={6} />
        <FormValidationMessage>{errors.message?.message}</FormValidationMessage>
      </InputContainer>
      <NewsletterCheckboxContainer>
        <NewsletterCheckbox
          type="checkbox"
          id="newsletter"
          defaultChecked={false}
          {...register('newsletter')}
        />
        <label htmlFor="newsletter">
          I would like to receive thought leadership content and updates about the Net Purpose
          platform.
        </label>
      </NewsletterCheckboxContainer>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ButtonContainer>
          <CallToActionButton type="submit" size="large" trackingName="Contact form - Submit">
            Submit
          </CallToActionButton>
        </ButtonContainer>
      )}
    </StyledContactForm>
  )
}

import NextLink from 'next/link'
import styled from 'styled-components'

export const Button = styled(NextLink)`
  display: flex;
  width: fit-content;
  align-items: center;
  font-size: 1.125rem;
  font-weight: bold;
  height: 2.75rem;
  border-radius: 1.375rem;
  padding: 0.75rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.actionPrimary};
  color: ${({ theme }) => theme.colors.typePrimary};
  white-space: nowrap;
  margin-bottom: ${({ theme }) => theme.spacing.regular};

  &:hover {
    color: ${({ theme }) => theme.colors.architecture1};
  }
` as typeof NextLink

import styled from 'styled-components'
import { framedSectionStyles, getBackgroundImageOrColor } from '#components/sections/shared.style'

export const NumberedStepsSectionContainer = styled.section<{
  imageUrl: string | undefined
  largeImageUrl: string | undefined
}>`
  ${framedSectionStyles}
  /* Medium image is too grainy to use by default here, as the container is
    quite tall on a narrow screen. */
  ${({ theme, largeImageUrl, imageUrl }) =>
    getBackgroundImageOrColor(theme, largeImageUrl, imageUrl)}
  background-size: cover;
  background-position: center;
  padding: ${({ theme }) => `${theme.spacing.large} ${theme.spacing.regular}`};
  text-align: center;
  color: ${({ theme }) => theme.colors.architecture1};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    // The default image url is the highest quality one.
    ${({ theme, imageUrl }) => getBackgroundImageOrColor(theme, imageUrl, imageUrl)}
    padding: ${({ theme }) => `${theme.spacing.medium} ${theme.spacing.large}`};
  }
`

export const StepsContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    gap: ${({ theme }) => theme.spacing.medium};
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }
`

export const Step = styled.div`
  flex: 1;
  margin-bottom: 4.5rem;
  font-size: 1.125rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const CircledNumber = styled.div<{ withIcon: boolean }>`
  display: flex;
  margin: 0 auto ${({ theme }) => theme.spacing.medium};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 14rem;
  height: 14rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.architecture1};
  font-family: Blacker, serif;
  font-size: 6rem;
  color: ${({ theme }) => theme.colors.typePrimary};
  padding-top: ${({ withIcon }) => (withIcon ? 0 : '1rem')};
`

export const Label = styled.h3`
  margin-bottom: 1.55rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-size: 1.5rem;
`

export const Icon = styled.img`
  width: 50%;
  height: 50%;
`

import styled from 'styled-components'

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    align-items: flex-start;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Input = styled.input`
  padding: 1rem 1.5rem;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.architecture3};
  width: 100%;
  height: 3rem;
  font-size: 1rem;
`

export const TextArea = styled.textarea`
  padding: 1rem 1.5rem;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.architecture3};
  width: 100%;
  resize: vertical;
  font-size: 1rem;
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.typePrimary};
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`

export const FormValidationMessage = styled.span`
  color: ${({ theme }) => theme.colors.semanticNegative};
  font-size: 1rem;
`

export const NewsletterCheckbox = styled.input`
  transform: scale(1.2);
  accent-color: ${({ theme }) => theme.colors.actionPrimary};
  margin-top: 0.375rem;
`

export const NewsletterCheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  gap: 1rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

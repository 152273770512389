import styled from 'styled-components'

export const Container = styled.section<{ whiteBackground?: boolean }>`
  background-color: ${({ theme }) => theme.colors.architecture2};
  padding: 5rem ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 10rem ${({ theme }) => theme.spacing.large};
  }
`

export const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
    justify-content: center;
  }
`

export const Testimonial = styled.div`
  width: 100%;
  max-width: 35rem;
`

export const TestimonialImage = styled.img<{
  applyHoverStyles?: boolean
}>`
  width: 100%;
  margin: ${({ theme }) => `0 auto ${theme.spacing.medium} auto`};
  /* To prevent box shrinking on hover. */
  border: 2px solid transparent;

  &:hover {
    border: ${({ theme, applyHoverStyles }) =>
      applyHoverStyles ? `2px solid ${theme.colors.actionPrimary}` : '2px solid transparent'};
    box-shadow: ${({ theme, applyHoverStyles }) =>
      applyHoverStyles ? `0 6px 10px ${theme.colors.architecture5}` : 'none'};
  }
`

export const TestimonialQuote = styled.p`
  text-align: center;
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.medium};
`

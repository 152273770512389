import NextLink from 'next/link'
import { Fade } from 'react-awesome-reveal'
import { PostWithoutBody } from '#cmsApi/posts'
import { CTASection } from '#cmsApi/types'
import { Main } from '#components/sections'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import { getFormattedShortDate } from '#lib/utils'
import { routes } from '#routes'
import { MediaSection } from './MediaSection'
import { PressReleases } from './PressReleases'
import {
  PostCallToActionText,
  PostDate,
  Posts,
  PostTitle,
  ResourceTitle,
  PostArticle as StyledPostArticle,
} from './Resources.style'

const PostArticle = ({
  customDate,
  publishedAt,
  title,
  slug,
  coverImage,
  type,
  link,
}: PostWithoutBody) => {
  const publishedDate = getFormattedShortDate(customDate || publishedAt)

  const isPressArticle = type === 'link'

  const backgroundImage = coverImage?.data?.attributes
  const { medium, large } = backgroundImage?.formats || {}

  const analytics = useAnalytics()

  return (
    <NextLink
      href={isPressArticle ? link || '' : routes.blog.post(slug)}
      passHref
      // TODO: refactor this to use the NextLink component only.
      legacyBehavior
    >
      <StyledPostArticle
        imageUrl={backgroundImage?.url}
        mediumImageUrl={medium?.url}
        largeImageUrl={large?.url}
        target={isPressArticle ? '_blank' : '_self'}
        rel={isPressArticle ? 'noopener' : ''}
        onClick={() => {
          analytics?.track(AnalyticsEventName.LinkClick, {
            link_name: `Post article - ${title}`,
          })
        }}
      >
        <Fade>
          <div>
            <PostDate>{publishedDate}</PostDate>
            <PostTitle>{title}</PostTitle>
          </div>
        </Fade>
        <PostCallToActionText>Read more...</PostCallToActionText>
      </StyledPostArticle>
    </NextLink>
  )
}

type Props = {
  posts: PostWithoutBody[]
  title: string
  mediaSection?: CTASection
  pressReleases?: PostWithoutBody[]
}

export const Resources = ({ posts, title, mediaSection, pressReleases }: Props) => {
  return (
    <Main>
      <ResourceTitle>{title}</ResourceTitle>
      <Posts>
        {posts.map((post) => (
          <PostArticle key={post.slug} {...post} />
        ))}
      </Posts>
      {mediaSection && <MediaSection {...mediaSection} />}
      {!!pressReleases?.length && <PressReleases posts={pressReleases} />}
    </Main>
  )
}

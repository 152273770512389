import { PostWithoutBody as PostType } from '#cmsApi/posts'
import { SectionHeading } from '#components/Headings'
import { getFormattedShortDate } from '#lib/utils'
import { routes } from '#routes'
import { Container, InnerContainer, Post, Posts, PostTitleLink } from './PressReleases.style'

type Props = {
  posts: PostType[]
}

export const PressReleases = ({ posts }: Props) => (
  <Container id="press-releases">
    <InnerContainer>
      <SectionHeading>Press releases</SectionHeading>
      <Posts>
        {posts.map((post) => {
          const isExternalLink = post.type === 'link'

          return (
            <Post key={post.slug}>
              <PostTitleLink
                href={isExternalLink ? post.link || '' : routes.blog.post(post.slug)}
                target={isExternalLink ? '_blank' : '_self'}
                rel={isExternalLink ? 'noopener' : ''}
              >
                {post.title}
              </PostTitleLink>
              <p>{getFormattedShortDate(post.customDate || post.publishedAt)}</p>
            </Post>
          )
        })}
      </Posts>
    </InnerContainer>
  </Container>
)

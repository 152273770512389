import { CTASection } from '#cmsApi/types'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { SectionHeading } from '#components/Headings'
import {
  BodyText,
  Container,
  Image,
  ImageContainer,
  InnerContainer,
  TextAndButtonContainer,
} from './MediaSection.style'

type Props = CTASection

export const MediaSection = ({ heading, image, callToAction, body, ctaLink }: Props) => {
  return (
    <Container>
      <InnerContainer>
        <ImageContainer>
          <Image src={image.data?.attributes.url} alt={image.data?.attributes.alternativeText} />
        </ImageContainer>
        <TextAndButtonContainer>
          <SectionHeading>{heading}</SectionHeading>
          <BodyText>{body}</BodyText>
          <CallToActionLink
            download
            href={ctaLink as string}
            trackingName={`Media section - ${callToAction}`}
          >
            {callToAction}
          </CallToActionLink>
        </TextAndButtonContainer>
      </InnerContainer>
    </Container>
  )
}

import styled from 'styled-components'

export type Variation = 'light' | 'dark'

export const VerticalCircleBannerContainer = styled.section<{ variation: Variation }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.5rem ${({ theme }) => theme.spacing.regular};
  background-color: ${({ variation, theme }) =>
    variation === 'dark' ? theme.colors.bgDark : theme.colors.bgSecondary};
  color: ${({ variation, theme }) =>
    variation === 'dark' ? theme.colors.architecture1 : theme.colors.typePrimary};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 4.5rem ${({ theme }) => theme.spacing.large};
  }
`

export const CircleImageContainer = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 18rem;
  height: 18rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.bgPrimary};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    width: calc(18rem * 1.25);
    height: calc(18rem * 1.25);
  }
`

export const CircleImage = styled.img`
  max-height: 14rem;
  max-width: 14rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: calc(14rem * 1.25);
    max-height: calc(14rem * 1.25);
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 34.375rem;

  > * {
    margin-top: 2.8rem;
  }
`

export const Heading = styled.h2`
  font-family: Blacker, serif;
  font-size: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 4.5rem;
  }
`

export const CTAImage = styled.img`
  width: 3.4375rem;
  height: 3.4375rem;
  object-fit: contain;
`

import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { TextAndImageSection } from '#cmsApi/types'

export const TextAndImageSectionContainer = styled.section<{
  whiteBackground: TextAndImageSection['whiteBackground']
}>`
  display: block;
  background-color: ${({ theme, whiteBackground }) =>
    whiteBackground ? theme.colors.bgSecondary : theme.colors.bgPrimary};
  padding: 5rem ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 10rem ${({ theme }) => theme.spacing.large};
  }
`

export const InnerContainer = styled.div<{ layout: TextAndImageSection['layout'] }>`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;

    display: flex;
    gap: ${({ theme }) => theme.spacing.large};
    flex-direction: ${({ layout }) => (layout === 'imageLeft' ? 'row' : 'row-reverse')};
  }
`

export const DesktopImageContainer = styled.div<{
  circleImage: TextAndImageSection['circleImage']
}>`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ circleImage }) => (circleImage ? '50%' : 'auto')};
    overflow: ${({ circleImage }) => (circleImage ? 'hidden' : 'visible')};
    width: 35vw;
    height: ${({ circleImage }) => (circleImage ? '35vw' : 'auto')};
    min-width: 20rem;
    min-height: 20rem;
    margin-bottom: ${({ theme }) => theme.spacing.regular};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    max-width: ${({ circleImage }) => (circleImage ? '35rem' : '40rem')};
    max-height: ${({ circleImage }) => (circleImage ? '35rem' : '40rem')};
  }
`

export const MobileTabletImageContainer = styled.div<{
  circleImage: TextAndImageSection['circleImage']
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75vw;
  height: ${({ circleImage }) => (circleImage ? '75vw' : 'auto')};
  max-width: 25rem;
  max-height: 25rem;
  border-radius: ${({ circleImage }) => (circleImage ? '50%' : 'auto')};
  overflow: ${({ circleImage }) => (circleImage ? 'hidden' : 'visible')};
  margin-bottom: 3.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`

export const Image = styled.img<{
  circleImage: TextAndImageSection['circleImage']
}>`
  width: 100%;
  height: ${({ circleImage }) => (circleImage ? '100%' : 'auto')};
  object-fit: ${({ circleImage }) => (circleImage ? 'cover' : 'unset')};
`

export const ContentContainer = styled.div<{ desktopCentered?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
    align-items: ${({ desktopCentered }) => (desktopCentered ? 'center' : 'flex-start')};
  }
`

export const Body = styled(ReactMarkdown)`
  font-size: 1.3125rem;

  p {
    margin-bottom: ${({ theme }) => theme.spacing.regular};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.5rem;
  }
`

export const MobileTabletLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: none;
  }
`

export const DesktopLinkContainer = styled.div<{ centerLink?: boolean }>`
  display: none;
  margin-top: ${({ theme }) => theme.spacing.medium};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    display: flex;
    width: 100%;
    justify-content: ${({ centerLink }) => (centerLink ? 'center' : 'flex-start')};
  }
`

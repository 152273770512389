import { StrapiImage } from '#cmsApi/types'
import { ContentContainer, Description, ItemContainer, ItemImage, Label } from './Item.style'

export type Props = {
  icon: StrapiImage
  label: string
  description: string | null
}

export const Item = ({ icon, label, description }: Props) => {
  const itemImage = icon.data?.attributes

  return (
    <ItemContainer>
      <ItemImage src={itemImage?.url} alt={itemImage?.alternativeText} />
      <ContentContainer>
        <Label>{label}</Label>
        {description && <Description>{description}</Description>}
      </ContentContainer>
    </ItemContainer>
  )
}

import { Fade } from 'react-awesome-reveal'
import { ProductFeatureSection } from '#cmsApi/types'
import { SectionHeading } from '#components/Headings'
import {
  Container,
  Icon,
  IconContainer,
  InnerContainer,
  Item,
  ItemDescription,
  ItemHeading,
} from './WhatWeDoSection.style'

type Props = ProductFeatureSection

export const WhatWeDoSection = ({ sectionTitle, productFeatureItem }: Props) => (
  <Container>
    <SectionHeading>{sectionTitle}</SectionHeading>
    <InnerContainer>
      {productFeatureItem.map((item) => (
        <Item key={item.id}>
          <IconContainer>
            <Icon
              src={item.icon.data?.attributes.url}
              alt={item.icon.data?.attributes.alternativeText}
            />
          </IconContainer>
          <Fade style={{ flex: 1 }}>
            <div>
              <ItemHeading>{item.label}</ItemHeading>
              <ItemDescription>{item.description}</ItemDescription>
            </div>
          </Fade>
        </Item>
      ))}
    </InnerContainer>
  </Container>
)

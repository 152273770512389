import styled, { css } from 'styled-components'

export const Container = styled.section<{ whiteBackground?: boolean }>`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  padding: 5rem ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 5rem ${({ theme }) => theme.spacing.large};

    a,
    button {
      max-width: 25rem;
    }
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
  }
`

const commonSectionStyles = css`
  padding: ${({ theme }) => `${theme.spacing.regular} 0`};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.medium};
  margin: 0 auto;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 45rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: start;
    flex: 1;
    align-items: flex-start;
    max-width: none;
    padding: ${({ theme }) => `${theme.spacing.medium} 0`};
  }
`

export const HiringSection = styled.div`
  ${commonSectionStyles};
  border-bottom: 1px solid ${({ theme }) => theme.colors.architecture8};
  padding-bottom: ${({ theme }) => theme.spacing.large};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding-right: ${({ theme }) => theme.spacing.medium};
    border-right: 1px solid ${({ theme }) => theme.colors.architecture8};
    border-bottom: none;
  }
`

export const NewsletterSectionContainer = styled.div<{ $isColumn: boolean }>`
  ${commonSectionStyles};
  padding-top: 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: ${({ $isColumn }) => ($isColumn ? 'column' : 'row')};
    gap: ${({ $isColumn, theme }) => ($isColumn ? theme.spacing.medium : theme.spacing.large)};
    padding-right: ${({ $isColumn, theme }) => ($isColumn ? 0 : theme.spacing.medium)};
    padding-left: ${({ theme }) => theme.spacing.medium};
  }
`
export const NewsletterTextContainer = styled.div`
  ${commonSectionStyles};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-basis: 60%;
    padding: 0;
  }
`

export const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.regular};
  width: 100%;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    align-items: flex-start;
  }
`

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacing.regular};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.architecture4};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 35rem;
  }
`

export const FormValidationMessage = styled.span`
  color: ${({ theme }) => theme.colors.semanticNegative};
`

export const SubmitMessage = styled.p`
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
`

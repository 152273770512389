import { useHydrated } from 'react-hydration-provider'
import { useLocalStorage } from 'usehooks-ts'
import { Banner, BannerMessage } from '#components/Banner'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { replaceMarkdownHighlightWithHTML } from '#lib/utils'
import { routes } from '#routes'
import { ScrollButton } from './ScrollButton'
import {
  CentredContentContainer,
  ScrollButtonTarget,
  Subtitle,
  Title,
  TitleSectionContainer,
} from './TitleSection.style'

type Props = {
  title: string
  subtitle: string | null // Markdown
  bannerMessage?: BannerMessage | undefined
  scrollButtonIsVisible?: boolean | undefined
  cta?: {
    text: string
    trackingName: string
  }
}

const TITLE_SECTION_SCROLL_TARGET = 'TITLE_SECTION_SCROLL_TARGET'

export const TitleSection = ({
  title,
  subtitle,
  bannerMessage,
  scrollButtonIsVisible,
  cta,
}: Props) => {
  const [dismissedBanners, setDismissedBanners] = useLocalStorage<string[]>('dismissedBanners', [])

  const isBannerDismissed = dismissedBanners.includes(bannerMessage?.id || '')

  const dismissBanner = () =>
    setDismissedBanners((prevState) => [...prevState, bannerMessage?.id as string])

  // Prevent server/client mismatch errors due to using local storage.
  const isHydrated = useHydrated()
  const isBannerVisible = Boolean(isHydrated && bannerMessage && !isBannerDismissed)

  return (
    <TitleSectionContainer isBannerVisible={isBannerVisible}>
      {isBannerVisible && (
        <Banner message={bannerMessage as BannerMessage} onDismissBanner={dismissBanner} />
      )}
      <CentredContentContainer>
        <Title
          // Using dangerouslySetInnerHTML here shouldn't be a problem as this comes from strapi and not user input.
          dangerouslySetInnerHTML={{ __html: replaceMarkdownHighlightWithHTML(title) }}
        ></Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {cta && (
          <CallToActionLink
            href={`${routes.contact()}?book_demo=true`}
            trackingName={cta.trackingName}
          >
            {cta.text}
          </CallToActionLink>
        )}
      </CentredContentContainer>
      {scrollButtonIsVisible && (
        <>
          <ScrollButton target={TITLE_SECTION_SCROLL_TARGET} />
          <ScrollButtonTarget id={TITLE_SECTION_SCROLL_TARGET} />
        </>
      )}
    </TitleSectionContainer>
  )
}

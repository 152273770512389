import { captureException, captureMessage, SeverityLevel, withScope } from '@sentry/nextjs'

const NEXT_PUBLIC_MONITORING_ENABLED = process.env['NEXT_PUBLIC_MONITORING_ENABLED']
const sentryEnabled = NEXT_PUBLIC_MONITORING_ENABLED
  ? NEXT_PUBLIC_MONITORING_ENABLED === 'true'
  : false

type ContextName = 'Extra Data' | 'Axios Error'

type ContextValue = Record<string, string | number | boolean | Record<string, unknown>>

const getCaptureInSentry =
  (severity: SeverityLevel) =>
  async ({
    err,
    context,
  }: {
    err: string | Error
    context?: { name: ContextName; value: ContextValue }
    // Where the string returned would be the ID of the Sentry event.
  }): Promise<void | string> => {
    if (severity === 'error') {
      console.error(err)
    } else {
      console.warn(err)
    }

    if (!sentryEnabled) {
      return
    }

    if (context) {
      return withScope((scope) => {
        scope.setContext(context.name, context.value)
        if (err instanceof Error) {
          return captureException(err, { level: severity })
        } else {
          return captureMessage(err, severity)
        }
      })
    } else if (typeof err === 'string') {
      return captureMessage(err, severity)
    } else {
      return captureException(err, { level: severity })
    }
  }

export const logger = {
  error: getCaptureInSentry('error'),
  warn: getCaptureInSentry('warning'),
}

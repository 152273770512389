import { keyframes } from 'styled-components'
import styled from 'styled-components'
import { LargeHeading, SectionHeading } from '#components/Headings'

export const Container = styled.section`
  padding: 5rem ${({ theme }) => theme.spacing.regular};
  background-color: ${({ theme }) => theme.colors.bgPrimary};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 10rem ${({ theme }) => theme.spacing.large};
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    flex-direction: row;
  }
`

export const TextContainer = styled.div`
  order: 2;
  text-align: center;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    order: 1;
    flex: 1;
    padding-right: ${({ theme }) => theme.spacing.large};
    text-align: left;
  }
`

export const StyledSectionHeading = styled(SectionHeading)`
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`

export const StyledLargeHeading = styled(LargeHeading)`
  order: 3;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    order: 1;
  }
`

export const Names = styled.div`
  display: flex;
  order: 1;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.regular};
  padding: ${({ theme }) => theme.spacing.medium} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 0;
    align-items: flex-start;
    margin-left: 10vw;
  }
`

export const Name = styled.label<{ selected: boolean }>`
  font-size: 1.5rem;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.typePrimary : theme.colors.typeSecondary};
  text-transform: uppercase;
  position: relative;
  left: ${({ selected }) => (selected ? '-0.5rem' : '0')};
  cursor: pointer;

  &::before {
    content: '';
    display: ${({ selected }) => (selected ? 'inline-block' : 'none')};
    position: relative;
    right: 2rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.actionPrimary};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    left: ${({ selected }) => (selected ? '-1rem' : '0')};
  }
`

export const Description = styled.p`
  order: 3;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin-top: 5rem;
  }
`

export const ImageContainer = styled.div`
  order: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    order: 2;
    width: 50%;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0.2;
  }

  10%, 90% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
`

export const FADE_ANIMATION_IN_MS = 3500

export const Image = styled.img`
  animation: ${fadeIn} ${FADE_ANIMATION_IN_MS}ms linear;
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  object-fit: contain;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 50rem;
  }
`

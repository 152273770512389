import Image from 'next/image'
import { StyledMenuButton } from './Header.style'

type Props = {
  isOpenState: boolean
  onClick: () => void
}

const MenuIcon = () => {
  return <Image src="/images/icons/menu.svg" alt="Menu icon" width={32} height={32} />
}

const CloseIcon = () => {
  return <Image src="/images/icons/close.svg" alt="Close icon" width={32} height={32} />
}

export const MenuButton = ({ onClick, isOpenState }: Props) => (
  <StyledMenuButton onClick={onClick}>
    {isOpenState ? <CloseIcon /> : <MenuIcon />}
  </StyledMenuButton>
)

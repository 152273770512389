import { useEffect } from 'react'
import { logger } from '@netpurpose/utils'

type Props = {
  errMessage: string
}

export const FullPageError = ({ errMessage }: Props) => {
  useEffect(() => {
    logger.error({ err: errMessage })
  }, [errMessage])

  return (
    <>
      <h1>Something went wrong</h1>
      <p>Please try again later</p>
    </>
  )
}

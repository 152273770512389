import React from 'react'
import { Footer, Header } from '#components/sections'
import { routes } from '#routes'
import { Container } from './layout.style'

type Props = {
  children: React.ReactNode
  activePage?: keyof typeof routes
  showHeader?: boolean
}

export const Layout = ({ children, activePage, showHeader = true }: Props) => (
  <>
    {showHeader && <Header activePage={activePage} />}
    <Container $withHeader={showHeader}>
      {children}
      <Footer withHeader={showHeader} />
    </Container>
  </>
)

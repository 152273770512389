import { ProductPageAttributes } from '#cmsApi/productPage'
import { Main, TitleSection } from '#components/sections'
import { CarouselSection } from '#components/sections/CarouselSection'
import { TextAndImageSection } from '#components/sections/TextAndImageSection'
import { HowItWorksSection } from './HowItWorksSection'
import { HowWeHelpSection } from './HowWeHelpSection'
import { WhatWeDoSection } from './WhatWeDoSection'

export const Product = ({
  titleSection,
  intro,
  whatWeDoSection,
  howYouGetItSection,
  howWeHelpYouSection,
  howItWorksSection,
}: ProductPageAttributes) => (
  <Main>
    <TitleSection scrollButtonIsVisible {...titleSection} />
    <TextAndImageSection {...intro} pageName="Product" />
    <WhatWeDoSection {...whatWeDoSection} />
    <HowItWorksSection {...howItWorksSection} />
    <HowWeHelpSection {...howWeHelpYouSection} />
    <CarouselSection {...howYouGetItSection} />
  </Main>
)

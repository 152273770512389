import { HomePageAttributes } from '#cmsApi/homePage'
import { BannerMessage } from '#components/Banner'
import { LargeCircleBanner } from '#components/sections'
import { Main } from '#components/sections/Main'
import { TextAndImageSection } from '#components/sections/TextAndImageSection'
import { routes } from '#routes'
import { TitleSection } from '../../sections/TitleSection'
import { CompanySlider } from './CompanySlider'
import { HiringAndNewsletterSection } from './HiringAndNewsletterSection'
import { PressSection } from './PressSection'

export const Home = ({
  bannerMessage,
  titleSection,
  companySlider,
  introOne,
  introTwo,
  productSection,
  teamSection,
  pressSection,
  largeCircleBanner,
  hiringAndNewsletterSection,
}: HomePageAttributes & { bannerMessage: BannerMessage | undefined }) => {
  return (
    <Main>
      <TitleSection bannerMessage={bannerMessage} scrollButtonIsVisible {...titleSection} />
      <TextAndImageSection {...introOne} pageName="Home" />
      <TextAndImageSection {...introTwo} pageName="Home" />
      <TextAndImageSection {...productSection} linkUrl={routes.product()} pageName="Home" />
      <TextAndImageSection {...teamSection} linkUrl={routes.who()} pageName="Home" />
      <PressSection {...pressSection} linkUrl={routes.press.list()} />
      <LargeCircleBanner {...largeCircleBanner} url={routes.contact()} pageName="Home" />
      <HiringAndNewsletterSection {...hiringAndNewsletterSection} />
      <CompanySlider {...companySlider} />
    </Main>
  )
}

import styled from 'styled-components'

export const Container = styled.section`
  padding: 2rem ${({ theme }) => theme.spacing.regular};
  background-color: ${({ theme }) => theme.colors.architecture4};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 5rem ${({ theme }) => theme.spacing.large};
  }
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
    align-items: flex-start;
  }
`

export const Posts = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    text-align: left;
  }
`

export const Post = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

export const PostTitleLink = styled.a`
  text-decoration: underline;
  display: block;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.typePrimary};
  font-weight: 700;

  &:hover {
    color: ${({ theme }) => theme.colors.actionPrimary};
  }
`

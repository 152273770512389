import styled from 'styled-components'

export const SectionContainer = styled.section`
  position: relative;
  display: block;
  padding: 5rem ${({ theme }) => theme.spacing.regular};
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 15rem ${({ theme }) => theme.spacing.large};
  }
`

export const Heading = styled.h2`
  font-family: Blacker, serif;
  font-size: 3.75rem;
  margin-bottom: 2.8rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 6rem;
    text-align: left;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    z-index: ${({ theme }) => theme.zIndices.semiCircleFeaturesSectionForeground};
    margin-right: 40%;
    margin-bottom: 5.3125rem;
    align-items: flex-start;
  }
`

export const SemiCircleImageContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    z-index: ${({ theme }) => theme.zIndices.semiCircleFeaturesSectionBackground};
    position: absolute;
    width: 50rem;
    height: 50rem;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.bgPrimary};
  }
`

import styled from 'styled-components'

export const shortHeaderHeight = '4rem'
export const tallHeaderHeight = '6.25rem'
const menuHiddenWidth = '750px'

export const Header = styled.nav<{ isMenuOpen: boolean }>`
  position: fixed;
  width: 100%;
  height: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : shortHeaderHeight)};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  z-index: ${({ theme }) => theme.zIndices.navMenu};

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    height: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : tallHeaderHeight)};
  }
`

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${shortHeaderHeight};
  padding: 0 ${({ theme }) => theme.spacing.regular};

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding: 0 ${({ theme }) => theme.spacing.large};
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    height: ${tallHeaderHeight};
  }
`

export const LeftSection = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    gap: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    gap: 2.5rem;
  }
`

export const RightSection = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  @media (min-width: ${menuHiddenWidth}) {
    display: flex;
    position: static;
    width: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    gap: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    gap: 2.5rem;
  }
`

export const NavContainer = styled.ul<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  padding: 0 ${({ theme }) => theme.spacing.regular};
  text-align: left;
  top: ${shortHeaderHeight};
  right: 0;
  /* Total height of ContactUsLinks. If that changes then this needs to be adjusted
    to ensure that the content doesn't overlap with the links on a short screen. */
  bottom: 9rem;
  left: 0;
  font-size: 1.75rem;
  z-index: ${({ theme }) => theme.zIndices.navMenu};
  list-style: none;
  overflow-y: auto;

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    overflow-y: visible;
    top: ${tallHeaderHeight};
  }

  @media (min-width: ${menuHiddenWidth}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 0;
    background-color: transparent;
    gap: 0.5rem;
    font-size: 1rem;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
    overflow: visible;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    gap: 1.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    gap: 2rem;
  }
`

export const LogoMenuButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: ${menuHiddenWidth}) {
    width: auto;
  }
`

export const LogoImg = styled.img`
  height: 1.5rem;
  cursor: pointer;

  @media (min-width: ${menuHiddenWidth}) {
    height: 1rem;
  }
`

export const StyledMenuButton = styled.button`
  display: block;
  border: none;
  background-color: transparent;
  cursor: pointer;

  @media (min-width: ${menuHiddenWidth}) {
    display: none;
  }
`

export const TextLink = styled.a<{ isActive: boolean }>`
  font-size: 1rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.typePrimary};
  font-weight: bold;
  text-underline-offset: 0.25rem;
  text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.colors.actionPrimary};
  }
`

export const SubMenuContainer = styled.li`
  > a {
    color: ${({ theme }) => theme.colors.typeTertiary};
  }

  @media (min-width: ${menuHiddenWidth}) {
    > a {
      color: ${({ theme }) => theme.colors.typePrimary};
    }

    height: 1.5rem;
  }
`

export const SubMenuDropdown = styled.ul<{ isVisible: boolean }>`
  display: block;
  list-style-type: none;

  @media (min-width: ${menuHiddenWidth}) {
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    position: relative;
    padding-inline-start: 0px;
    padding-bottom: 0.5rem;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.architecture1};
    border-radius: 4px;
  }
`

export const SignInWrapper = styled.div`
  display: none;

  @media (min-width: ${menuHiddenWidth}) {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
  }
`

export const ContactUsLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;

  @media (min-width: ${menuHiddenWidth}) {
    flex-direction: row;
    gap: 0.5rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    gap: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}) {
    gap: 1.5rem;
  }
`

export const routes = {
  home: () => '/',
  product: () => '/product',
  how: () => '/how',
  who: () => '/who',
  blog: {
    list: () => '/blog',
    post: (slug: string) => `/blog/${slug}`,
  },
  press: {
    list: () => '/press',
  },
  events: {
    list: () => '/events',
    event: (slug: string) => `/events/${slug}`,
  },
  hiring: () => '/hiring',
  contact: () => '/contact',
}

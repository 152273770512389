import styled from 'styled-components'
import { LoadingSvg } from './LoadingSvg'

export const LoadingIcon = styled(LoadingSvg)`
  @keyframes loadingCircle {
    100% {
      transform: rotate(360deg);
    }
  }
`

export const LoadingIconContainer = styled.div`
  height: 4.375rem;
`

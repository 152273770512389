import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useState } from 'react'
import { animateScroll } from 'react-scroll'
import ScrollLock from 'react-scrolllock'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import { routes } from '#routes'
import {
  ContactUsLinks,
  HeaderContainer,
  LeftSection,
  LogoImg,
  LogoMenuButtonContainer,
  NavContainer,
  RightSection,
  SignInWrapper,
  Header as StyledHeader,
  SubMenuContainer,
  SubMenuDropdown,
  TextLink,
} from './Header.style'
import { MenuButton } from './MenuButton'

type HeaderLinkProps = NextLinkProps & {
  text: string
  isActive: boolean
  isExternalLink?: boolean
}

const HeaderLink = (props: HeaderLinkProps) => {
  const analytics = useAnalytics()

  return (
    <NextLink
      href={props.href}
      passHref
      // TODO: refactor this to use the NextLink component only.
      legacyBehavior
    >
      <TextLink
        isActive={props.isActive}
        target={props.isExternalLink ? '_blank' : '_self'}
        onClick={() => {
          analytics?.track(AnalyticsEventName.LinkClick, { link_name: `Header - ${props.text}` })
        }}
      >
        {props.text}
      </TextLink>
    </NextLink>
  )
}

type Props = {
  activePage: keyof typeof routes | undefined
}

export const Header = ({ activePage }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isResourceMenuOpen, setIsResourceMenuOpen] = useState(false)

  const toggleMenu = () => {
    animateScroll.scrollToTop({ duration: 0 })
    setIsMenuOpen((prev) => !prev)
  }

  return (
    <StyledHeader isMenuOpen={isMenuOpen}>
      <HeaderContainer>
        <LeftSection>
          <LogoMenuButtonContainer>
            <NextLink href="/">
              <LogoImg
                className="logo"
                src="/images/netpurpose-logo-black.svg"
                alt="Net Purpose logo"
              />
            </NextLink>
            <MenuButton isOpenState={isMenuOpen} onClick={toggleMenu} />
          </LogoMenuButtonContainer>
          <ScrollLock isActive={isMenuOpen}>
            <NavContainer $isVisible={isMenuOpen}>
              <li>
                <HeaderLink
                  href={routes.product()}
                  text="Product"
                  isActive={activePage === 'product'}
                />
              </li>
              <li>
                <HeaderLink href={routes.how()} text="How" isActive={activePage === 'how'} />
              </li>
              <li>
                <HeaderLink href={routes.who()} text="Who" isActive={activePage === 'who'} />
              </li>
              <SubMenuContainer
                onMouseEnter={() => {
                  setIsResourceMenuOpen(true)
                }}
                onMouseLeave={() => {
                  setIsResourceMenuOpen(false)
                }}
                onKeyDown={() => {
                  setIsResourceMenuOpen(true)
                }}
              >
                <HeaderLink
                  href={routes.blog.list()}
                  text="Resources"
                  isActive={['blog', 'press'].includes(activePage || '')}
                />
                <SubMenuDropdown isVisible={isResourceMenuOpen}>
                  <li>
                    <HeaderLink
                      href={routes.blog.list()}
                      text="Blog"
                      isActive={activePage === 'blog'}
                    />
                  </li>
                  <li>
                    <HeaderLink
                      href={routes.press.list()}
                      text="Press"
                      isActive={activePage === 'press'}
                    />
                  </li>
                  <li>
                    <HeaderLink
                      href={routes.events.list()}
                      text="Events"
                      isActive={activePage === 'events'}
                    />
                  </li>
                </SubMenuDropdown>
              </SubMenuContainer>
              <li>
                <HeaderLink
                  href={routes.hiring()}
                  text="Hiring"
                  isActive={activePage === 'hiring'}
                />
              </li>
            </NavContainer>
          </ScrollLock>
        </LeftSection>
        <RightSection $isVisible={isMenuOpen}>
          <SignInWrapper>
            <HeaderLink
              href="https://app.netpurpose.com/login"
              text="Sign in"
              isActive={false}
              isExternalLink
            />
          </SignInWrapper>
          <ContactUsLinks>
            <CallToActionLink
              href={routes.contact()}
              level="secondary"
              trackingName="Header - Talk to us"
            >
              Talk to us
            </CallToActionLink>
            <CallToActionLink
              href={`${routes.contact()}?book_demo=true`}
              trackingName="Header - Book demo"
            >
              Book Demo
            </CallToActionLink>
          </ContactUsLinks>
        </RightSection>
      </HeaderContainer>
    </StyledHeader>
  )
}

import { Fade } from 'react-awesome-reveal'
import { HowWeHelpYouSection, ProductFeatureItem, StrapiImage } from '#cmsApi/types'
import { SectionHeading } from '#components/Headings'
import {
  HowWeHelpHeading,
  HowWeHelpItemContainer,
  HowWeHelpItemImage,
  HowWeHelpSectionContainer,
  ItemContainer,
  LabelContainer,
} from './HowWeHelpSection.style'

type Props = HowWeHelpYouSection

type NumberedStepProps = {
  stepNumber: number
  label: string
  image: StrapiImage | null
}

const HowWeHelpItem = ({ label, image }: NumberedStepProps) => (
  <ItemContainer>
    <Fade>
      {image && (
        <HowWeHelpItemImage
          src={image.data?.attributes.url}
          alt={image.data?.attributes.alternativeText}
        />
      )}
    </Fade>
    <Fade>
      <LabelContainer>{label}</LabelContainer>
    </Fade>
  </ItemContainer>
)

export const HowWeHelpSection = ({
  sectionTitle,
  sectionHeading,
  backgroundImage,
  productFeatureItem,
}: Props) => {
  const backgroundImageFoo = backgroundImage?.data?.attributes
  const { large } = backgroundImageFoo?.formats || {}
  return (
    <HowWeHelpSectionContainer imageUrl={backgroundImageFoo?.url} largeImageUrl={large?.url}>
      <SectionHeading>{sectionTitle}</SectionHeading>
      <HowWeHelpHeading>{sectionHeading}</HowWeHelpHeading>
      <HowWeHelpItemContainer>
        {productFeatureItem.map((step, index) => {
          const { label, icon } = step as ProductFeatureItem
          return <HowWeHelpItem key={label} stepNumber={index + 1} label={label} image={icon} />
        })}
      </HowWeHelpItemContainer>
    </HowWeHelpSectionContainer>
  )
}

import { Fade } from 'react-awesome-reveal'
import ReactMarkdown from 'react-markdown'
import { IconCTASection } from '#cmsApi/types'
import {
  CircleImage,
  CircleImageContainer,
  ContentContainer,
  CTAImage,
  Heading,
  Variation,
  VerticalCircleBannerContainer,
} from './VerticalCircleBanner.style'

type Props = IconCTASection & {
  url: string
  variation: Variation
}

export const VerticalCircleBanner = ({
  image,
  heading,
  body,
  callToActionImage,
  url,
  variation,
}: Props) => {
  const circleImage = image.data?.attributes
  const ctaImage = callToActionImage.data?.attributes

  return (
    <VerticalCircleBannerContainer variation={variation}>
      <CircleImageContainer>
        <CircleImage src={circleImage?.url} alt={circleImage?.alternativeText} />
      </CircleImageContainer>
      <ContentContainer>
        {heading && (
          <Fade>
            <Heading>{heading}</Heading>
          </Fade>
        )}
        {body && (
          // div wrapper required for correct spacing.
          <div>
            <Fade>
              <ReactMarkdown>{body}</ReactMarkdown>
            </Fade>
          </div>
        )}
        <Fade>
          <a href={url}>
            <CTAImage src={ctaImage?.url} alt={ctaImage?.alternativeText} />
          </a>
        </Fade>
      </ContentContainer>
    </VerticalCircleBannerContainer>
  )
}
